<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <b>Bem-vindo! Preencha seus dados abaixo para realizar sua inscrição</b>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <validation-observer ref="observer">
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="enrolledTypeId"
            >
              <v-select
                v-model="enrolled.enrolledTypeId"
                :items="enrolledTypes"
                item-value="id"
                item-text="description"
                label="Tipo"
                hide-details="auto"
                outlined
                dense
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="name"
            >
              <v-text-field
                v-model="enrolled.name"
                hide-details="auto"
                label="Nome e sobrenome"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="nickname"
            >
              <v-text-field
                v-model="enrolled.nickname"
                hide-details="auto"
                label="Apelido (opcional)"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="occupation"
            >
              <v-text-field
                v-model="enrolled.occupation"
                hide-details="auto"
                label="Formação/cargo/setor"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="isPf || isCity">
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="cityId"
            >
              <v-autocomplete
                :loading="loadingCities"
                v-model="enrolled.cityId"
                :items="cities"
                item-value="id"
                item-text="name"
                label="Cidade"
                hide-details="auto"
                outlined
                dense
                autocomplete="nope"
                auto-select-first
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="email"
            >
              <v-text-field
                v-model="enrolled.email"
                hide-details="auto"
                label="E-mail"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="phoneNumber"
            >
              <v-text-field
                v-model="enrolled.phoneNumber"
                hide-details="auto"
                label="Telefone"
                v-mask="'(##) # ####-####'"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="isPj || isSponsorExhibitor">
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              vid="companyName"
            >
              <v-text-field
                v-model="enrolled.companyName"
                hide-details="auto"
                label="Nome instituição/empresa"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col class="px-5 text-right">
          <v-btn
            color="teal accent-4"
            dark
            @click="save"
            :loading="loadingSave"
          >
            <v-icon 
              v-text="'mdi-ticket-confirmation-outline'"
              left
            />
            Inscrever-se
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
  import eventsApi from '@/api/events'
  import enrolledTypesApi from '@/api/enrolled-types'
  import citiesApi from '@/api/cities'
  import enrolledApi from '@/api/enrolled'

  export default {

    data: () => ({
      loadingEnrolledTypes: false,
      loadingCities: false,
      loadingSave: false,
      enrolledTypes: [],
      cities: [],
      enrolled: { enrolledTypeId: null },
    }),

    async mounted () {
      this.loadEnrolledTypes()
      this.loadCities()
    },

    computed: {
      isPf () { return this.enrolled?.enrolledTypeId === 1 },
      isPj () { return this.enrolled?.enrolledTypeId === 2 },
      isSponsorExhibitor () { return this.enrolled?.enrolledTypeId === 3 },
      isCity () { return [4, 5, 6, 7, 8].includes(this.enrolled?.enrolledTypeId) },
    },

    methods: {
      async loadEvent () {
        try {
          this.loadingEvent = true
          const response = await eventsApi.get(this.$route.params.eventId)
          this.event = response.data.event
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingEvent =false
        }
      },

      async loadEnrolledTypes () {
        try {
          this.loadingEnrolledTypes = true
          const response = await enrolledTypesApi.list()
          this.enrolledTypes = response.data.enrolledTypes
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingEnrolledTypes =false
        }
      },

      async loadCities () {
        try {
          this.loadingCities = true
          const response = await citiesApi.list()
          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCities =false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          await enrolledApi.insert(this.$route.params.eventId, this.enrolled)

          this.$router.push(`/inscricao/${this.$route.params.eventId}/finalizar`)
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave =false
        }
      }
    }
  }
</script>
