var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('b',[_vm._v("Bem-vindo! Preencha seus dados abaixo para realizar sua inscrição")])])],1),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"enrolledTypeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.enrolledTypes,"item-value":"id","item-text":"description","label":"Tipo","hide-details":"auto","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.enrolled.enrolledTypeId),callback:function ($$v) {_vm.$set(_vm.enrolled, "enrolledTypeId", $$v)},expression:"enrolled.enrolledTypeId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome e sobrenome","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.name),callback:function ($$v) {_vm.$set(_vm.enrolled, "name", $$v)},expression:"enrolled.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Apelido (opcional)","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.nickname),callback:function ($$v) {_vm.$set(_vm.enrolled, "nickname", $$v)},expression:"enrolled.nickname"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"occupation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Formação/cargo/setor","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.occupation),callback:function ($$v) {_vm.$set(_vm.enrolled, "occupation", $$v)},expression:"enrolled.occupation"}})]}}])})],1)],1),(_vm.isPf || _vm.isCity)?_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"cityId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"loading":_vm.loadingCities,"items":_vm.cities,"item-value":"id","item-text":"name","label":"Cidade","hide-details":"auto","outlined":"","dense":"","autocomplete":"nope","auto-select-first":"","error-messages":errors},model:{value:(_vm.enrolled.cityId),callback:function ($$v) {_vm.$set(_vm.enrolled, "cityId", $$v)},expression:"enrolled.cityId"}})]}}],null,false,2817151070)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-mail","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.email),callback:function ($$v) {_vm.$set(_vm.enrolled, "email", $$v)},expression:"enrolled.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"}],attrs:{"hide-details":"auto","label":"Telefone","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.phoneNumber),callback:function ($$v) {_vm.$set(_vm.enrolled, "phoneNumber", $$v)},expression:"enrolled.phoneNumber"}})]}}])})],1)],1),(_vm.isPj || _vm.isSponsorExhibitor)?_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome instituição/empresa","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.enrolled.companyName),callback:function ($$v) {_vm.$set(_vm.enrolled, "companyName", $$v)},expression:"enrolled.companyName"}})]}}],null,false,1936360778)})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"px-5 text-right"},[_c('v-btn',{attrs:{"color":"teal accent-4","dark":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-ticket-confirmation-outline')}}),_vm._v(" Inscrever-se ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }